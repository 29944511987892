import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRow, IonTitle, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import { useAuth } from '../Common/authContext';
import { focusManager } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from 'react';
import EventContext, { LoadSummaryData, LoadVolunteerData } from '../Common/EventContext';
import SettingsContext, { EventList } from '../Common/SettingsContext';
import { currencyFormat } from '../Common/utils';
import './SummaryComponent.css';
import {
    informationCircleOutline,
    informationCircleSharp,
    closeCircleOutline,
    closeCircleSharp,
    callOutline
} from 'ionicons/icons';
import parse from 'html-react-parser';
import { VolunteerData } from '../Models/volunteer';


interface SummaryProps {
    refreshCount: number;
    showToast: boolean;
}


const SummaryComponent: React.FC<SummaryProps> = (props) => {
    const [presentToast] = useIonToast();
    const [presentAlert] = useIonAlert();
    const volDescModal = useRef<HTMLIonModalElement>(null);
    const modalMessage = useRef<HTMLIonContentElement>(null);
    //console.log("Counter:", props.refreshCount);

    const { authInfo } = useAuth()!;
    const access_token = authInfo.user.access_token;

    let { logOut } = useAuth()!;

    // Loader
    const [showLoading, setShowLoading] = useState(false);

    // Defaulted Event
    const [eventDefaulted, setEventDefaulted] = useState(false);

    //  Event Context Values
    //
    const { eventData, setEventData } = useContext(EventContext);
    const { Events, TeamName, summaryData, volunteerData } = eventData;

    //  Settings Context values
    //
    const { data, setData } = useContext(SettingsContext);
    const { ViewAs, CurrentEvent, Theme, MobileDeposit } = data;
    const event = CurrentEvent;
    const { EventDate, EventDesc, PhotoUrl } = event
    const badgeUrl = `/assets/img/badges/${event.EventName}.png`

    //  Caclulate the number for days to the selected Event
    //
    const startDate = new Date(Date()).getTime();
    const endDate = new Date(EventDate).getTime();
    const days = (endDate - startDate) / (1000 * 60 * 60 * 24);

    // Set up whether to show or hide Weekend CTA
    //
    const [showWeekendCTA, setShowWeekendCTA] = useState(false);

    // Volunteer Jobs Description modal
    //
    const [volDescOpen, setvolDescOpen] = useState(false);
    const [volJobDesc, setvolJobDesc] = useState('');

    //const [progressAmt, setProgress] = useState(0);

    const setDefaultEvent = (List: string) => {
        // Default is pmc. If we are not registered for pmc, set it to the first.
        //
        let Event;
        Event = CurrentEvent;

        if (!eventDefaulted) {
            Event = EventList.pmc;
            if (List !== null) {
                if (!List.includes('pmc')) {
                    const events = List.split(',');
                    
                    if (events[0] === 'unpaved') {
                        Event = EventList.unpaved;
                    }
                    if (events[0] === 'wintercycle') {
                        Event = EventList.wintercycle;
                    }
                    setData({ CurrentEvent: Event, ViewAs: 'self', Theme, MobileDeposit });
                }
            }
            setEventDefaulted(true);
        }
        return Event;
    }

    const setMobileDeposit = (Enabled: boolean, Event: any) => {
        // Set the mobile deposit enabled or not based on summary
        //
        setData({ CurrentEvent: Event, ViewAs, Theme, MobileDeposit: Enabled });
    }

    const setCTAStatus = (ShowDate: any, HideDate: any) => {
        if (ShowDate === null || HideDate === null) {
            setShowWeekendCTA(false);
        } else {
            var now = new Date();
            var show = new Date(ShowDate);
            var hide = new Date(HideDate);
            if (show < now && hide > now) {
                setShowWeekendCTA(true);
            } else {
                setShowWeekendCTA(false);
            }
        }
    }

    const toastEm = () => {
        presentToast({
            message: 'Last refreshed ' + new Date().toLocaleString() + '.',
            duration: 2000,
            position: 'bottom',
            cssClass: 'lastRefresh'
        });
    }

    const ShowDescription = (crew: string, position: string, desc: string) => {
        let txt = '<strong>' + crew;
        if (position !== '' && position !== null) {
            txt += ' - ' + position;
        }
        txt += '</strong><br/><br/>' + desc;

        if (desc !== '' && desc !== null) {
            setvolJobDesc(txt);
            setvolDescOpen(true);
        }
    }

    const VolDismissed = () => {
        setvolDescOpen(false);
    }

    // ShowAlert - Called to fire the alert
    //
    const showAlert = (data: any, buttons: any) => {
        presentAlert({
            header: data.header,
            message: data.message,
            backdropDismiss: false,
            buttons: buttons
        });
    }

    const LoadData = async (showToast: boolean) => {
        
        setShowLoading(true);
        console.log('About to call load data');
        // Load the summary from server
        const theSummary = await LoadSummaryData(event.EventName, authInfo.user.Main_ID, access_token);
 
        if (theSummary.EventList===null||theSummary.EventList===undefined) {
            const volData: VolunteerData = {
                Jobs: []
              }
            setEventData({
                volunteerData: volData,
                Events: Events,
                TeamName: TeamName,
                IsTeamCaptain: false,
                summaryData: summaryData
            });
            setShowLoading(false);
        }else if(theSummary.EventList==='401'){
            logOut(CurrentEvent.SecureUrl);
        }else{
            let volData: any;
            // 2024-04-15   mdi if volunteering, load jobs
            volData = await LoadVolunteerData(event.EventName, authInfo.user.Main_ID, access_token);
            if (theSummary.IsVolunteer) {
                //volData = await LoadVolunteerData(event.EventName, authInfo.user.Main_ID, access_token);
            }else{
               // volData = null;
            }

            setEventData({
                volunteerData: volData,
                Events: theSummary.EventList,
                TeamName: theSummary.TeamName,
                IsTeamCaptain: theSummary.IsCaptain,
                summaryData: theSummary
            });

            // set default event based on the user
            const defEvent = setDefaultEvent(theSummary.EventList);
            
            // set mobile deposit based on user
            setMobileDeposit(theSummary.MobileDeposit, defEvent);

            // set the CTA to show or hide
            setCTAStatus(theSummary.CTAShowDate, theSummary.CTAHideDate);

            setShowLoading(false);
            if (showToast) {
                toastEm();
            }
        }
    }

    const progress = () => {
        let prog = 0;
        if (ViewAs === 'self') {
            if (summaryData.Raised > 0 && summaryData.Goal > 0) {
                prog = Math.ceil(((summaryData.Raised / summaryData.Goal) * 100) / 5) * 5;
            }
        } else {
            if (summaryData.TeamRaised > 0 && summaryData.TeamGoal > 0) {
                prog = Math.ceil(((summaryData.TeamRaised / summaryData.TeamGoal) * 100) / 5) * 5;
            }
        }
        if (prog > 100) {
            prog = 100;
        }
        return prog;
    }

    const displayWelcome = () => {
        let welcome = "";
        if (ViewAs === 'self') {
            welcome = authInfo.user.ProfileName;

        } else {
            if (TeamName != null) {
                welcome = TeamName.startsWith('Team') ? TeamName : 'Team ' + TeamName;
            }
        }

        if (!welcome.endsWith('s')) {
            welcome = welcome + "'s";
        } else {
            welcome = welcome + "'";
        }

        return welcome;
    }



    useEffect(() => {
        //console.log("Summary:", summaryData);
        LoadData(false);
        focusManager.setFocused(false)
        return () => focusManager.setFocused(undefined)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        LoadData(props.showToast);
        // eslint-disable-next-line
    }, [props.refreshCount])

    useEffect(() => {
        LoadData(false);
        // eslint-disable-next-line
    }, [event, ViewAs])

    if (summaryData !== null && summaryData !== undefined) {
        return (
            <>
                {showWeekendCTA && (
                    <>
                        <IonCard className="cta">
                            <IonCardContent>
                            {event.EventName!=='unpaved' && (
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size='6'>
                                            <IonItem button detail={false} lines='none' href="https://track.rtrt.me/e/PMC-PANMASS-2024" className='fullbutton'>
                                                <IonAvatar slot="start">
                                                    <img src={process.env.PUBLIC_URL + '/assets/img/RTRT.png'} alt='RTRT' />
                                                </IonAvatar>
                                                <IonLabel>
                                                    Rider Tracking
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size='6'>
                                            <IonItem button detail={false} lines='none' href="http://traq.cc/app" target='_blank'>
                                                <IonAvatar slot="start">
                                                    <img src={process.env.PUBLIC_URL + '/assets/img/Traq.png'} alt='Traq Central' />
                                                </IonAvatar>
                                                <IonLabel>
                                                    TraQCentral
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                    <IonCol size='12'>
                                            <IonItem button detail={false} lines='none' href="tel://+18009329253" className='fullbutton'>
                                                <IonAvatar slot="start">
                                                    <img src={process.env.PUBLIC_URL + badgeUrl} alt={EventDesc} />
                                                </IonAvatar>
                                                <IonLabel className='callHelp'>
                                                    Call for Help
                                                </IonLabel>
                                                
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>)}
                                {event.EventName==='unpaved' && (
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size='6'>
                                                <IonItem button detail={false} lines='none' href="tel://+18009329253" className='fullbutton'>
                                                    <IonAvatar slot="start">
                                                        <img src={process.env.PUBLIC_URL + badgeUrl} alt={EventDesc} />
                                                    </IonAvatar>
                                                    <IonLabel>
                                                        Call for Help
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size='6'>
                                                <IonItem button detail={false} lines='none' href="http://traq.cc/app" target='_blank'>
                                                    <IonAvatar slot="start">
                                                        <img src={process.env.PUBLIC_URL + '/assets/img/Traq.png'} alt='Traq Central' />
                                                    </IonAvatar>
                                                    <IonLabel>
                                                        TraQCentral
                                                    </IonLabel>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>)}
                            </IonCardContent>
                        </IonCard>
                    </>
                )}
                <IonCard className="summary">
                    <IonCardHeader>
                        <IonCardTitle><img src={`${process.env.PUBLIC_URL}/assets/img/title/${event.EventName}.png`} alt={EventDesc} /></IonCardTitle>
                        {Math.trunc(days) > 0 && (
                            <IonCardSubtitle>{Math.trunc(days)} days to {EventDate}</IonCardSubtitle>
                        )}
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow className='greeting'>
                                <IonCol>{displayWelcome()}</IonCol>
                            </IonRow>

                            <IonRow className='eventHistory'>
                                <IonCol>{EventDesc}</IonCol>
                            </IonRow>

                            {ViewAs !== 'team' &&
                                <IonRow className='totalStats'>
                                    <IonCol size='4'>Raised LTD<br />{currencyFormat(summaryData.TotalRaised)}</IonCol>
                                    <IonCol size='3'>Years<br />{summaryData.TYears}</IonCol>
                                    <IonCol size='2'>Rider<br />{summaryData.PYears}</IonCol>
                                    <IonCol size='3'>Volunteer<br />{summaryData.VYears}</IonCol>
                                </IonRow>
                            }

                            <IonRow>
                                <IonCol size='4'>
                                    <IonImg src={`${PhotoUrl}${ViewAs === 'self' ? summaryData.ProfilePhoto : summaryData.TeamProfilePhoto}`} alt={`${ViewAs === 'self' ? authInfo.user.ProfileName : TeamName}'s profile photo`} />
                                </IonCol>
                                <IonCol className='goalBox' size='5'><strong>Total Raised</strong><br />
                                    {ViewAs === 'self' ? currencyFormat(summaryData.Raised) : currencyFormat(summaryData.TeamRaised)}<br />
                                    <br />
                                    <strong>Goal</strong><br />
                                    {ViewAs === 'self' ? currencyFormat(summaryData.Goal) : currencyFormat(summaryData.TeamGoal)}<br />
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/ProgressBar/MTBankLogo.jpg`} alt='MT Bank' className='sponsorLogo' />
                                </IonCol>
                                <IonCol size='3'>
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/ProgressBar/${progress()}.png`} alt='Progress' className='progressBar' />
                                </IonCol>
                            </IonRow>

                            {TeamName !== null && ViewAs !== 'team' &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>Team</IonCardTitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <IonItem lines='full'>
                                            {TeamName}
                                        </IonItem>
                                    </IonCardContent>

                                </IonCard>
                            }

                            {summaryData.EventOptionDesc !== null && ViewAs !== 'team' &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            {event.EventName!=='wintercycle' && (<>Route</>)}
                                            {event.EventName==='wintercycle' && (<>Riding</>)}
                                        </IonCardTitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <IonItem lines='full'>
                                            {summaryData.EventOptionDesc.indexOf('(') > 0 &&
                                                <IonLabel>
                                                    {summaryData.EventOptionDesc.substring(0, summaryData.EventOptionDesc.indexOf('(') - 1)}<br />
                                                    {summaryData.EventOptionDesc.substring(summaryData.EventOptionDesc.indexOf('(') - 1)}
                                                </IonLabel>
                                            }
                                            {summaryData.EventOptionDesc.indexOf('(') <= 0 &&
                                                <IonLabel>
                                                    {summaryData.EventOptionDesc}
                                                    {summaryData.TimeSlots !== null && summaryData.TimeSlots !== undefined && (
                                                        <><br/><strong>Sessions:</strong><br/>
                                                        {summaryData.TimeSlots?.split(', ').map((line, index) => (
                                                            <>
                                                            {line}
                                                            <br />
                                                            </>
                                                        ))}</>
                                                    )}
                                                </IonLabel>
                                            }

                                        </IonItem>
                                    </IonCardContent>

                                </IonCard>
                            }

                            {summaryData.TransOptionDesc !== null && ViewAs !== 'team' &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>Transportation</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonItem lines='full'>
                                            <IonLabel>
                                                {summaryData.TransOptionDesc}
                                            </IonLabel>
                                        </IonItem>

                                    </IonCardContent>
                                </IonCard>
                            }

                            {summaryData.RoomInfo !== null && ViewAs !== 'team' &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>Lodging</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonItem lines='full'>
                                            <IonLabel>
                                                {summaryData.RoomInfo.replace('<br />', '\n')}
                                            </IonLabel>
                                        </IonItem>

                                    </IonCardContent>
                                </IonCard>
                            }

                            {summaryData.FridayLodgingOptionDesc !== null && ViewAs !== 'team' &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>Friday Lodging</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonItem lines='full'>
                                            <IonLabel>
                                                {summaryData.FridayLodgingOptionDesc.replace('<br />', '\n')}
                                            </IonLabel>
                                        </IonItem>

                                    </IonCardContent>
                                </IonCard>
                            }

                            {summaryData.SaturdayLodgingOptionDesc !== null && ViewAs !== 'team' &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>Saturday Lodging</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonItem lines='full'>
                                            <IonLabel>
                                                {summaryData.SaturdayLodgingOptionDesc.replace('<br />', '\n')}
                                            </IonLabel>
                                        </IonItem>

                                    </IonCardContent>
                                </IonCard>
                            }

                            {summaryData.IsVolunteer && ViewAs !== 'team' && volunteerData !== null && volunteerData !== undefined && volunteerData.Jobs.length>0 &&
                                <IonCard className='eventRegData'>
                                    <IonCardHeader>
                                        <IonCardTitle>Volunteering</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {volunteerData.Jobs.map((job, index) => (<>
                                            <IonItem lines='full' onClick={() => ShowDescription(job.CrewDesc, job.PositionDesc, job.description)} key={job.VolReqID}>
                                                <IonLabel>
                                                    {event.EventName === 'pmc' &&
                                                        <>
                                                            <strong>{job.SiteDesc}</strong><br />
                                                            {job.CrewDesc}<br />
                                                        </>
                                                    }
                                                    {event.EventName !== 'pmc' &&
                                                        <>
                                                            <strong>{job.CrewDesc}</strong><br />
                                                        </>
                                                    }
                                                    {job.PositionDesc !== '' && job.PositionDesc != null &&
                                                        <>
                                                            {job.PositionDesc}<br />
                                                        </>
                                                    }
                                                    {job.TimeSlot}

                                                </IonLabel>
                                                {job.description !== '' && job.description !== null &&
                                                    <IonIcon slot="end" ios={informationCircleOutline} md={informationCircleSharp} />
                                                }
                                            </IonItem>
                                        </>))}
                                        <IonModal ref={volDescModal} isOpen={volDescOpen} onDidDismiss={() => VolDismissed()}>
                                            <IonHeader>
                                                <IonToolbar>
                                                    <IonTitle>Job Description</IonTitle>

                                                    <IonButtons slot="end">
                                                        <IonButton onClick={() => volDescModal.current.dismiss()}><IonIcon slot="end" ios={closeCircleOutline} md={closeCircleSharp} /></IonButton>
                                                    </IonButtons>
                                                </IonToolbar>
                                            </IonHeader>
                                            <IonContent className="ion-padding">
                                                {parse(volJobDesc)}
                                            </IonContent>
                                        </IonModal>
                                    </IonCardContent>
                                </IonCard>

                            }

                        </IonGrid>
                        <IonLoading
                            isOpen={showLoading}
                            message={'Loading...'}
                        />
                    </IonCardContent>
                </IonCard>
            </>
        );
    }

};

export default SummaryComponent;
