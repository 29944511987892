//import { type } from "os";
import { createContext } from "react";

export const EventList = {
  pmc: {
    Enabled: true,
    EventName: 'pmc',
    EventDesc: 'PMC 2024',
    EventDate: '8/3/2024',
    SecureUrl: 'https://secure.pmc.org/pl/',
    PhotoUrl: 'https://profile.pmc.org/images/pfpphotos/',
    HandbookUrl: 'https://view.publitas.com/pan-mass-challenge/2024-pmc-handbook/page/1',
    EventColor: '#3D5B7E',
    Participant: {
      OpenDate: "01/09/2024",
      OpenText: "Register for the PMC",
      CloseDate: "07/31/2024",
      ClosedText: "Registration is closed for the PMC",
      RegisterUrl: "https://secure.pmc.org/pl/ride/" 
    },
    Volunteer: {
      OpenDate: "02/28/2024",
      OpenText: "Volunteer for the PMC",
      CloseDate: "07/31/2024",
      ClosedText: "Volunteer Registration is closed for the PMC",
      RegisterUrl: "https://secure.pmc.org/pl/volunteer" 
    },
    About: `<h1>Closer by the Mile</h1>
          
    <p>The Pan-Mass Challenge raises money for life-saving cancer research and treatment at Dana-Farber Cancer Institute through an annual bike-a-thon that crosses the Commonwealth of Massachusetts. <br /><br />
    Since its founding in 1980, the PMC has successfully melded support from committed cyclists, volunteers, corporate sponsors and individual contributors.<br /><br />
    All are essential to the PMC's goal and model: to attain maximum fundraising efficiency while increasing its annual gift.<br/><br />
    Our hope and aspiration is to provide Dana-Farber's doctors and researchers with the necessary resources to discover cures for all cancers.<br/><br /></p>
    <p><a href='mailto:app@pmchq.org'>Click here for help</a></p>`
  },
  unpaved: {
    Enabled: true,
    EventName: 'unpaved',
    EventDesc: 'PMC Unpaved 2024',
    EventDate: '9/28/2024',
    SecureUrl: 'https://secure.pmc.org/pl/unpaved/',
    PhotoUrl: 'https://profile.unpaved.org/pfpphotos/',
    HandbookUrl: 'https://view.publitas.com/pan-mass-challenge/pmc-unpaved-handbook-2024/page/1',
    EventColor: '#26564f',
    Participant: {
      OpenDate: "04/02/2024",
      OpenText: "Register for Unpaved",
      CloseDate: "09/28/2024",
      ClosedText: "Registration opens to Summit Fundraisers on 4/2/2024, to alumni on 4/4/2024, and to the general public on 4/9/2024.",
      RegisterUrl: "https://secure.unpaved.org/pl/ride" 
    },
    Volunteer: {
      OpenDate: "04/02/2024",
      OpenText: "Volunteer for Unpaved",
      CloseDate: "09/28/2024",
      ClosedText: "Registration opens to alumni on 4/4/2024 and to the general public on 4/9/2024.",
      RegisterUrl: "https://secure.unpaved.org/pl/volunteer" 
    },
    About: `<h1>About PMC Unpaved</h1>
          
    <p>PMC Unpaved is a gravel bike ride over the scenic backroads of the Berkshires in western Massachusetts to raise funds for Dana-Farber Cancer Institute. PMC Unpaved is a spoke in the wheel of the Pan-Mass Challenge, a bike-a-thon that raises more money for charity than any other single athletic fundraising event in the country.</p>
    <p>The inaugural PMC Unpaved was held in 2022.</p>`
  },
  wintercycle: {
    Enabled: true,
    EventName: 'wintercycle',
    EventDesc: 'PMC Winter Cycle 2025',
    EventDate: '2/1/2025',
    SecureUrl: 'https://secure.pmc.org/pl/wintercycle/',
    PhotoUrl: 'https://profile.wintercycle.org/images/pfpphotos/',
    HandbookUrl: '',
    EventColor: '#00AEEF',
    Participant: {
      OpenDate: "10/16/2024",
      OpenText: "Register for the Winter Cycle",
      CloseDate: "02/02/2025",
      ClosedText: "Registration is closed for Winter Cycle",
      RegisterUrl: "https://secure.wintercycle.org/pl/register" 
    },
    Volunteer: {
      OpenDate: "10/16/2024",
      OpenText: "Volunteer for Winter Cycle",
      CloseDate: "02/02/2025",
      ClosedText: "Volunteer Registration is closed for Winter Cycle",
      RegisterUrl: "https://secure.wintercycle.org/pl/volunteer" 
    },
    About: `<h1>About the PMC Winter Cycle</h1>
          
    <p>The PMC Winter Cycle, a spoke in the wheel of the Pan-Mass Challenge is an indoor spin event held at Fenway Park in Boston each year. The PMC Winter Cycle is comprised of five 45-minute sessions led by popular spin instructors and brings together hundreds of people who channel physical, mental and emotional energy to fight cancer by raising funds for Dana-Farber Cancer Institute.</p>
    <p>The 2025 PMC Winter Cycle is February 1, 2025, at Fenway Park.</p>`
  },
}

type EventType = {
  EventName: string,
  EventDesc: string,
  EventDate: string,
  SecureUrl: string,
  PhotoUrl: string,
  HandbookUrl: string,
  EventColor: string,
  Enabled: boolean,
  About: string,
}

export type SettingsContextType = {
  CurrentEvent: EventType,
  ViewAs: string,
  Theme: String,
  MobileDeposit: Boolean
}

//  Determine if a theme has already been selected
//
let theme = localStorage.getItem('theme')
if (!theme) {
  theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  localStorage.setItem('theme', theme);
}

if (theme === 'dark') {
  //console.log('Turning on Dark Mode');
  document.body.classList.toggle('dark');
}

export const SettingsDefaultValues = {
  data: {
    CurrentEvent: EventList.pmc,
    ViewAs: 'self',
    Theme: theme,
    MobileDeposit: false,
  } as SettingsContextType,
  setData: (state: SettingsContextType) => { }
};

const SettingsContext = createContext(SettingsDefaultValues);

export default SettingsContext;